<template>
  <div class="back_img">
    <div class="login_title">
      <img
        src="../../../public/img/logo.png"
        alt
      />
    </div>
    <div class="login-box">
      <div class="formbox">
        <div class="login_text">验证码登录</div>
        <div class="login_text_english">sign in</div>
        <el-form
          :model="ruleForm"
          status-icon
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm login_myform"
        >
          <el-form-item prop="telephone">
            <div class="user_info">
            用户名
          </div>
            <el-input
              v-model="ruleForm.telephone"
              type="text"
              clearable
              placeholder="请输入手机号"
              size="big"
              class="mywant"
            ></el-input>
          </el-form-item>

          <el-form-item prop="verificationCode">
          <div class="user_info" style="margin-top:8px;">
            验证码
          </div>
            <el-input
              v-model="ruleForm.verificationCode"
              type="text"
              placeholder="短信验证码"
              autocomplete="off"
              clearable
              size="big"
              class="mywant"
            >
              <el-button
                slot="suffix"
                type="text"
                class="send-code"
                :disabled="disabledSendVerifyBtn"
                @click="sendVerifyCode"
                size="medium"
              >{{ sendVerifyBtnText }}</el-button>
            </el-input>
          </el-form-item>

          <el-button
            type="primary"
            @click="submitForm('ruleForm')"
            class="loginBtn"
            size="medium"
          >登 录</el-button>
        </el-form>
        <div class="login-code">
          <div
            @click="toLogin"
          >密码登录</div>
        </div>
      </div>
    </div>

    <!-- <div class="bottom">
      <a
        href="http://www.beian.miit.gov.cn/"
        style="color: #8E8E8E;"
      >蜀ICP备15030794号</a>
      Copyright 2020 绿源集科技有限公司
    </div> -->
    <div class="bottom">
      <div>
        <a target="_blank" href=" " >
          <img src="@/assets/beian.png" alt="" style="width:14px" >
          <span>川公网安备 51010602001572号</span>
        </a>
        <span style="margin:0 5px">|</span>
        <a target="_blank" href="https://beian.miit.gov.cn/" >蜀ICP备15030794号</a>
      </div>
      <div>Copyright 2015 - 2022 四川绿源集科技有限公司版权所有</div>
    </div>
  </div>
</template>

<script>
import encryptor from "@/service/encryptor";
import { isPhone } from "@/service/common";

export default {
  data() {
    let validatePhone = (rule, value, callback) => {
      if (isPhone(value)) {
        callback();
      }
      callback(new Error("手机号码格式不正确"));
    };
    return {
      ruleForm: {
        telephone: "",
        verificationCode: "",
      },
      rules: {
        telephone: [
          { required: true, trigger: "blur", message: "请输入手机号" },
          { validator: validatePhone, trigger: "blur" },
        ],
        verificationCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
      },
      remainTimes: 0,
      serverPublicKey: "",
      browserPrivKey: `-----BEGIN PRIVATE KEY-----
MIICdwIBADANBgkqhkiG9w0BAQEFAASCAmEwggJdAgEAAoGBALtYAyWdJncxSqKa
TX8UuxmIIfszfIqPqkpFkCdtNcuVWfmEVVN+s1LybCrkrZkl51bVNtGZjBhS1yGv
0MqTDaWOLlipNvYCfn0x+SxnAUdfWoJakKAZ3jNhRC2ktMziRZx+8M9q7sJrtE6y
rhrq+CYtFcLcIJXLtuCXI2/xeqJhAgMBAAECgYAOcLDBZ/UMj+iUUvdxOaxYQfV4
ktXhKkSyd90uJM1WNVtbj7cx+dyhopzyy3CUEudbepDApCi3wT7i0lXyH1aYT6qu
VWyj2ow9le3dXbYqpDW+XwhCSD+ApTmH+fC1IuH8pO9ZajK5PNgz/j38eZQjJGKW
Yq3G4qfyL5+Q/R4i6QJBAPl0HuHMBErit3N3V1j9BzNrMDZlk74u857KSd8o7l8O
8T9wTHgfgXKmY1wDYAB5V2fq2rVUVfy5vJ5SpkWkme0CQQDAQqAkqqhgaYS3Nkgd
hb2OJ5g9hqICjYc6wF9m8k+YkW6QqcVijC9IZNCiTg3sXV/VukldJEyOtlnwxTPB
nwvFAkEAz9+hYH5X0WOuhk0b/+8TEhkvmxT4klKj914RQyLjyVk1Vq0pPCzc/YKE
aAfz91EmslU2nkbCRREuiizLnFt3XQJBAJYH5/x1CGwkK6LlQyV2plN2ZoEsvOSN
GQgp8sjdMmv2oXdWZGfgyU0PDxh5HLhkrVKVyoZauztq5YjT+u2PdZkCQGsKw45l
T9zlVFz8L2pASIbGFSJdaOOmMJg1hgBfR8BLSn4WxamVMMXdCZTzqXwR+nasbI8P
14aL9H/y1fmy2wE=
-----END PRIVATE KEY-----`,

      browserPubKey: `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC7WAMlnSZ3MUqimk1/FLsZiCH7
M3yKj6pKRZAnbTXLlVn5hFVTfrNS8mwq5K2ZJedW1TbRmYwYUtchr9DKkw2lji5Y
qTb2An59MfksZwFHX1qCWpCgGd4zYUQtpLTM4kWcfvDPau7Ca7ROsq4a6vgmLRXC
3CCVy7bglyNv8XqiYQIDAQAB
-----END PUBLIC KEY-----`,
    };
  },
  computed: {
    disabledSendVerifyBtn: function () {
      return this.remainTimes > 0;
    },
    sendVerifyBtnText: function () {
      if (this.remainTimes > 0) {
        return "重新发送" + this.remainTimes + "s";
      } else {
        return "发送验证码";
      }
    },
  },
  methods: {
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (!valid) return;
        const loading = this.$loading();
        return this.axios
          .post("/store/platform/getPlatformPublicKey")
          .then((res) => {
            this.serverPublicKey = res.data.public_key; //获取到公钥
            let rsaPassWord = encryptor.encrypt({
              publicKey: this.serverPublicKey,
              password: this.ruleForm.pass,
            });
            let data = {
              phone: this.ruleForm.telephone,
              pwd: rsaPassWord,
              public_key: window.btoa(this.browserPubKey),
              type: 2,
              sms_code: this.ruleForm.verificationCode,
            };
            return this.axios.post("/store/login/Login", data);
          })
          .then((res) => {
            if (res.code === 0) {
              // 1. 使用浏览器端私钥解密 token 得到明文 token 用于加密 api
              let userInfo = res.data.info;
              let module_id_array = res.data.info.module_id_array.split(',');
              let encryptedToken = res.data.token;
              let exposedToken = encryptor.decrypt({
                privateKey: this.browserPrivKey,
                encryptedText: encryptedToken,
              });
              // 2. 请求头中的 token 需要使用服务器公钥加密
              let headerToken = encryptor.encrypt({
                publicKey: this.serverPublicKey,
                password: exposedToken,
              });
              window.utils.storage.setter("userInfo", userInfo);
              window.utils.storage.setter("headerToken", headerToken);
              window.utils.storage.setter("exposedToken", exposedToken);
              window.utils.storage.setter("module_id_array", module_id_array);
              this.$router.push("/home/workbenchNew");
            } else if (res.data.failes >= 3 && res.data.failes < 5) {
              this.Code = true;
              this.refreshCode();
            } else if (res.data.failes >= 5) {
              this.Code = true;
              this.refreshCode();
              this.$message.error("错误次数过多请30分钟后次登录");
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            loading.close();
          });
      });
    },
    //发送验证码
    sendVerifyCode() {
      const telephone = this.ruleForm.telephone;
      if (telephone !== "" && isPhone(telephone)) {
        // 发送验证码
        let data = {
          msg_type: 1,
          phone: telephone,
        };
        const loading = this.$loading();
        this.axios
          .post("/store/Platform/sendMsg", data)
          .then((res) => {
            // 弹框提示
            if (res.code === 0) {
              this.$message.success("验证码已发送"), this.startTimer(120);
            }
          })

          .finally(() => {
            loading.close();
          });
      } else {
        this.$refs.ruleForm.validateField("telephone");
      }
    },
    //验证码倒计时
    startTimer(times) {
      this.remainTimes = times;
      let setInterval = window.setInterval(() => {
        if (this.remainTimes > 0) {
          this.remainTimes--;
        } else {
          window.clearInterval(setInterval);
        }
      }, 1000);
    },
    //密码登录
    toLogin() {
      this.$router.push({
        path: "/",
        query: {
          phone: this.ruleForm.telephone,
        },
      });
    },
  },
  created() {
    if (this.$route.query.phone) {
      this.ruleForm.telephone = this.$route.query.phone;
    }
  },
};
</script>


<style lang="scss">
.mywant .el-input__inner {
  height: 56px;
  font-size: 18px;
}
.send-code{
  height: 100%;
  font-size: 18px;
}
.back_img {
  width: 100%;
  height: 100vh;
  background: url('../../assets/newLogin.png') center center no-repeat;
  background-size: 100% 100%;
  // padding-top: 24vh;
  // position: fixed;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-box{
  width: 1000px;
  height: 576px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.formbox {
  width: 420px;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0px 12px 26px 10px rgba(97, 144, 139, 0.14);
  border-radius: 5px;
  // margin: 0 auto;
  padding: 70px 40px 50px 40px;
  box-sizing: border-box;
}
.pwdBox {
  display: flex;
  justify-content: space-between;
}
.loginBtn {
  width: 340px;
  height: 55px;
  font-size: 18px;
  margin: 22px 0 24px 100px;
}
.login_myform {
  margin-left: -100px;
  margin-top: 35px;
}
.user_info{
  font-size: 20px;
  color: #333333;
  height: 30px;
  line-height: 30px;
}
.login_text {
  height: 36px;
  font-size: 26px;
  color: #262626;
  text-align: left;
  line-height: 36px;
}
.login_text_english{
  height: 36px;
  font-size: 18px;
  color: #999999;
  line-height: 36px;
}
.login-code{
  height: 23px;
  line-height: 23px;
  font-size: 16px;
  cursor: pointer;
  color: #4974f5;
}
.codeInput {
  width: 209px;
}
.login_title {
  width: 100%;
  // background-color: rgba(17, 14, 122, 0.42);
  // box-shadow: 0px 1px 2px 0px rgba(0, 21, 41, 0.12);
  position: fixed;
  top: 0px;
  text-align: left;
  padding-left: 85px;
  padding-top: 20px;
  span {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #ffffff;
    margin-left: 20px;
  }
}
.bottom {
  width: 100%;
  height: 58px;
  line-height: 29px;
  font-size: 14px;
  color: #c1c1c1;
  position: absolute;
  bottom: 10px;
  text-align: center;
  a{
    color: #c1c1c1;
  }
}
</style>
